import '../App.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import ButtonDefault from '../../../components/buttonDefault';
import IconButtonDefault from '../../../components/iconButton';
import { TypographDefault, ParagraphDefault } from '../../../components/typograph';
import { useHistory, useParams } from 'react-router-dom';
import { putUpdatePassword } from '../../../services/associate/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Reset() {

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const { token } = useParams();

    const history = useHistory();
    const cancel = () => {
        history.push('/login');
    };

    const [backErrors, setBackErrors] = useState({});
    const clearBackErrors = (field) => {
        backErrors[field] = null;
        setBackErrors(backErrors);
    };

    const resetValidationSchema = Yup.object().shape({
        password: Yup.string()
            .nullable()
            .required('Campo Obrigatório')
            .min(8, 'A senha precisa conter no mínimo 8 caracteres.')
            .test(
                'isEqual',
                'As senhas não correspondem!',
                () => (password === passwordConfirmation)
            ),

        passwordConfirmation: Yup.string()
            .nullable()
            .required('Campo Obrigatório')
            .min(8, 'A senha precisa conter no mínimo 8 caracteres.')
            .test(
                'isEqual',
                'As senhas não correspondem!',
                () => (password === passwordConfirmation)
            )
    });

    return (
        <div className="container-fluid">
            <Formik
                initialValues={{
                    password,
                    passwordConfirmation,
                }}
                enableReinitialize
                validationSchema={resetValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    putUpdatePassword({ password, token }).then(response => {
                        history.push('/login');
                    }).catch(err => {
                        setBackErrors({
                            password: err.response.data.errors[0]?.message
                        })
                    })
                    setSubmitting(true);
                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <div>
                        <div id="header" className="px-5 my-3">
                            <div>
                                <img className="img-fluid mt-3" src="/logomei.svg" hei />
                            </div>
                            <div>
                                <img className="img-fluid mt-3" src="/blackacsplogo.svg" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center" style={{ height: "70vh" }}>
                            <div className="col-12">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-6 m-0 p-0 d-flex align-items-center flex-wrap">
                                        <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
                                            <h1 style={{ color: "#002c43" }}>
                                                <FontAwesomeIcon
                                                    className="back-icon mr-3 icon-hover"
                                                    icon={faArrowLeft}
                                                    size="1x"
                                                    onClick={() => history.push("/home")}
                                                    color="#002c43"
                                                    style={{ hover: "cursor: pointer" }}
                                                />
                                                Recuperar senha
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-5 my-3">
                                        <ParagraphDefault paragraphData={{
                                            _class: 'text-muted font-weight-bold h5',
                                            text: 'Informe a sua nova senha'
                                        }} />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-5">
                                        <p className="text-muted font-weight-bold m-0">Nova Senha <label className="text-danger">*</label></p>
                                        <input
                                            id="pass"
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="Informe a nova senha"
                                            onChange={(password) => {
                                                clearBackErrors('password');
                                                setPassword(password.target.value);
                                                setFieldValue(
                                                    'password',
                                                    password.target.value,
                                                );
                                            }}
                                        />
                                        <small className="col-12 p-0 text-left text-danger">
                                            {(errors.password
                                                && touched.password
                                                && errors.password) || backErrors.password}
                                        </small>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="col-12 col-md-5">
                                        <p className="text-muted font-weight-bold m-0">Confirmar nova Senha <label className="text-danger">*</label></p>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="passwordConfirmation"
                                            placeholder="Confirme a nova senha"
                                            onChange={(passwordConfirmation) => {
                                                clearBackErrors('passwordConfirmation');
                                                setPasswordConfirmation(passwordConfirmation.target.value);
                                                setFieldValue(
                                                    'passwordConfirmation',
                                                    passwordConfirmation.target.value,
                                                );
                                            }}
                                        />
                                        <small className="col-12 p-0 text-left text-danger">
                                            {(errors.passwordConfirmation
                                                && touched.passwordConfirmation
                                                && errors.passwordConfirmation) || backErrors.passwordConfirmation}
                                        </small>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="row col-12 col-md-5 d-flex justify-content-between align-items-center">
                                        <ButtonDefault buttonData={{
                                            variant: 'none',
                                            action: () => handleSubmit(),
                                            text: 'Redefinir',
                                            textColor: '#000',
                                            _class: 'createAccout_btn font-weight-bold col mr-1 mt-2',
                                        }} />
                                        <ButtonDefault buttonData={{
                                            variant: 'none',
                                            action: () => cancel(),
                                            text: 'Cancelar',
                                            textColor: '#87cefa',
                                            _class: 'recovery__btn--cancel font-weight-bold col mt-2',
                                        }} />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="row col-12 col-md-6 m-0 p-0 d-flex align-items-center flex-wrap">
                                        {
                                            token === false ?
                                                <>
                                                    <IconButtonDefault iconButtonData={{
                                                        variant: "none",
                                                        action: () => console.log(''),
                                                        icon: 'bi bi-exclamation-triangle-fill',
                                                        iconSize: '2rem',
                                                        iconColor: '#e9655f',
                                                        _class: 'pl-3'
                                                    }} />
                                                    <div className="col pr-5">
                                                        <p className="text-danger m-0"> Por questões de segurança o tempo para recuperação de senha foi excedido, <a href="#">clique aqui</a> para fazer nova solicitação. </p>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </Formik>
        </div>
    );
}

export default Reset;

